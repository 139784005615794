<template>
    <Header />
    <SoundList />
</template>

<script>
import Header from './components/Header.vue'
import SoundList from './components/SoundList.vue'

export default {
    name: 'App',
    components: {
        Header,
        SoundList
    },
}
</script>

<style lang="scss">
@import '../node_modules/modern-normalize/modern-normalize.css';

html {
    font-size: 16px;
    color: white;
    background-color: black;
    display: flex;
    height: 100%;

    @media (max-width: 768px) { 
        font-size: 14px;
    }
}

body {
    margin: auto;
    width: 100%;
}

a {
    color: white;
}
</style>
