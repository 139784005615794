<template>
    <div class="header">
        <div 
            class="header__icon mute"
            :class="{'header__icon--active': isMuted}" 
            @click="toggleMute"
            title="Pause"
        >
            <template v-if="isMuted">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
            <template v-else>
               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
        </div>

        <div
            class="header__icon"
            @click="openoverlay"
            title="Info"
        >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </div>
    </div>

    <div
        ref="overlay"
        class="overlay"
    >
        <div @click="closeoverlay" class="overlay__close">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>

        <div class="overlay__container">
            <h2>Credits and Attributions</h2>
            <p>Icons made by <a target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/authors/smashicons">Smashicons</a> from <a target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/eclipseprophet/sounds/84969/">decemberrain.flac by eclipseprophet</a> is licensed under CC BY 3.0</p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/theafterdinnerdialogues/sounds/475545/">LOUD - Dual Mic'd - Heavy Rain from Grimbergen with Possible Thunder.wav by theafterdinnerdialogues</a> is licensed under CC BY 3.0</p>          
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/Qpiee/sounds/347639/">Thunderclap mix with rain (short).wav by Qpiee</a> is licensed under CC BY 3.0</p>          
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/hansende/sounds/263994/">Fireplace 3 hours by hansende</a> is licensed under CC BY 3.0</p>          
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/hansende/sounds/263995/">Crashing Ocean Waves (3 hours) To Relax, Sleep, or Meditate by hansende</a> is licensed under CC BY 3.0</p>          
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/ERH/sounds/34338/">wind.wav by ERH</a> is licensed under CC BY 3.0</p>          
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/inchadney/sounds/56611/">Morning in the country.WAV by inchadney</a> is licensed under CC BY 3.0</p>          
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/Clearwavsound/sounds/523439/">Night time crickets call by Clearwavsound</a> is licensed under CC BY 3.0</p>          
            <p><a target="_blank" rel="noopener noreferrer" href="https://freesound.org/people/Benboncan/sounds/64544/">Tawny Owls 2.wav by Benboncan</a> is licensed under CC BY 3.0</p>          
        </div>
    </div> 

</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            isMuted: false
        }
    },
    methods: {
        toggleMute() {
            this.isMuted = !this.isMuted
        },
        openoverlay() {
            this.$refs.overlay.style.display = 'flex'
        },
        closeoverlay() {
            this.$refs.overlay.style.display = 'none'
        }
    }
}
</script>

<style lang="scss">
.header {
    position: fixed;
    top: 0;
    right: 0;
    margin: 2rem;
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) { 
        flex-direction: column-reverse;
    }

    &__icon {
        opacity: 0.3;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }

        &--active {
            opacity: 1 !important;
        }
    }

    svg {
        width: 2rem;
        height: 2rem;
        display: block;
    }
}

.overlay {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: black;
    overflow: auto;

    &__close {
        position: fixed;
        top: 0;
        right: 0;
        margin: 2rem;
        cursor: pointer;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    &__container {
        max-width: 800px;
        padding: 4rem 2rem;
        margin: auto;
    }
}
</style>
