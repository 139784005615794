<template>
    <div 
        class="sound"
        :class="{'sound--active': isActive}" 
    >
        
        <div
            class="sound__icon"
            :title="name"
            @click="toggleActive">
            <slot></slot>
        </div>

        <audio
            ref="audio"
            controls
            loop
            :src="file">
        </audio>

        <input 
            class="sound__volume"
            :class="{'sound__volume--hidden': !isActive}" 
            @input="updateVolume"
            v-model="volume"
            type="range"
            :max="volumeMax"
        >
    </div>
</template>

<script>
export default {
    name: 'Sound',
    props: {
        name: String,
        file: String
    },
    data() {
        return {
            isMuted: false,
            isActive: false,
            volume: 50,
            volumeMax: 100
        }
    },
    mounted() {
        document.querySelector('.mute').addEventListener('click', e => {
            this.toggleMute()
        })
    },
    methods: {
        toggleMute() {
            this.isMuted = !this.isMuted
            this.isMuted ? this.$refs.audio.muted = true : this.$refs.audio.muted = false
        },
        toggleActive() {
            this.isActive = !this.isActive
            this.isActive ? this.$refs.audio.play() : this.$refs.audio.pause()
        },
        updateVolume() {
    		this.$refs.audio.volume = this.volume / this.volumeMax
        }
    },
}
</script>

<style lang="scss">
.sound {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    &__icon {
        opacity: 0.3;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }

        svg {
            width: 18vw;
            height: 18vw;
            max-width: 100px;
            max-height: 100px;
            display: block;

            path {
                fill: white;
            }
        }
    }

    &--active &__icon {
        opacity: 1;
    }

    audio {
        display: none;
    }

    &__volume {
        visibility: visible;

        &--hidden {
            visibility: hidden;
        }
    }
}

// https://www.cssportal.com/style-input-range/
input[type=range] {
  height: 22px;
  -webkit-appearance: none;
  width: 100%;
  max-width: 130px;

    @media (max-width: 768px) { 
        max-width: 100px;
    }

  background: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: white;
  border-radius: 50px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid white;
  height: 18px;
  width: 18px;
  border-radius: 50px;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: white;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: white;
  border-radius: 50px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid white;
  height: 18px;
  width: 18px;
  border-radius: 50px;
  background: white;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: white;
  border: 0px solid #000000;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: white;
  border: 0px solid #000000;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid white;
  height: 18px;
  width: 18px;
  border-radius: 50px;
  background: white;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: white;
}
input[type=range]:focus::-ms-fill-upper {
  background: white;
}
</style>
